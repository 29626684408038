<template>
  <div>
    <div class="row">
      <div class="col-24">
        <h2 class="title-separator-left">Success</h2>
        <p class="text-muted">Thanks! Your account successfully verified. Now you have access to all site features.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-24">
        <form>
          <div>
            <button type="button" class="btn btn-block btn-bc btn-purple-900" @click="onOk">
              Ok
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async onOk() {
      this.$store.commit('loading/start')
      await this.$router.push({ name: 'HomePage' })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
