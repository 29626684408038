<template>
  <div class="min-vh-100 d-flex wave-bg">
    <AuthorizedHeader></AuthorizedHeader>
    <Layout>
      <div class="container-fluid">
        <div class="view pt-4 pb-2">
          <template v-if="step === 1">
            <AccountVerification @done="toVerificationCodeChallenge"></AccountVerification>
          </template>
          <template v-if="step === 2">
            <VerificationCodeChallenge
                @cancel="toAccountVerification"
                @done="toSuccessfulVerification"
                :operationId="operationId"
            ></VerificationCodeChallenge>
          </template>
          <template v-if="step === 3">
            <SuccessfulVerification></SuccessfulVerification>
          </template>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import AuthorizedHeader from '@/components/header/AuthorizedHeader'
import Layout from '@/components/sidebar/Layout'
import AccountVerification from '@/views/verification/AccountVerification'
import VerificationCodeChallenge from '@/views/verification/VerificationCodeChallenge'
import SuccessfulVerification from '@/views/verification/SuccessfulVerification'

export default {
  components: {
    AuthorizedHeader,
    Layout,
    AccountVerification,
    VerificationCodeChallenge,
    SuccessfulVerification,
  },
  data() {
    return {
      step: 1,
      operationId: null,
    }
  },
  methods: {
    toVerificationCodeChallenge(operationId) {
      this.operationId = operationId
      this.next()
    },
    toAccountVerification() {
      this.operationId = null
      this.previous()
    },
    toSuccessfulVerification() {
      this.operationId = null
      this.next()
    },
    next() {
      this.step += 1
    },
    previous() {
      this.step -= 1
    }
  }
}
</script>

<style scoped lang="scss">
.view {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
</style>
