<template>
  <div>
    <div class="row">
      <div class="col-24">
        <h2 class="title-separator-left">Account Verification</h2>
        <p class="text-muted">
          Please enter an email to verify your account. This lets you create your own rooms and adds the option of two-factor authentication.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-24 pt-3 text-center" v-if="errors.length">
        <span class="text-danger" v-for="(error, index) in errors" :key="index">{{error}}</span>
      </div>
      <div class="col-24 pt-3">
        <form>
          <div class="form-group">
            <label for="email" ref="emailLabel" v-animate:shake="errorLabelAnimate">Email</label>
            <input type="text" name="email" id="email" class="form-control" v-focus v-model="email">
            <small class="form-text text-danger" v-for="(error, index) in emailErrors" :key="index">
              {{error}}
            </small>
          </div>
          <div class="pt-2">
            <ProcessingBtn type="submit" class="btn btn-block btn-bc btn-purple-900" :isProcessing="isProcessing" :onClick="submit">Continue</ProcessingBtn>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from '@/config'
import { getApiAuth } from '@/utils/credentials.utils'
import focus from '@/directives/focus'
import animate from '@/directives/animate'
import ProcessingBtn from '@/components/common/ProcessingBtn'

const { apiUrl } = config

export default {
  directives: {
    focus,
    animate
  },
  components: {
    ProcessingBtn
  },
  data() {
    return {
      isProcessing: false,
      email: '',
      errors: [],
      emailErrors: [],
      errorLabelAnimate: {
        clsList: ['text-danger']
      }
    }
  },
  methods: {
    resetErrors() {
      this.errors = []
      this.emailErrors = []
    },
    async submit(e) {
      e.preventDefault()
      this.resetErrors()
      if (this.validate()) {
        await this.sendEmailVerificationCode()
      }
    },
    validate() {
      let isValid = true
      if (!this.email.length) {
        this.$refs.emailLabel.animate()
        isValid = false
      }
      return isValid
    },
    async sendEmailVerificationCode() {
      this.isProcessing = true
      let response
      try {
        response = await this.$http.post(apiUrl + '/account/email/verification', {
          email: this.email,
        }, {
          auth: getApiAuth()
        })
      } catch (e) {
        this.isProcessing = false
        if (!_.isNil(e.response)) {
          const respResult = e.response.data.result
          if (respResult.error === 'Conflict') {
            this.emailErrors.push('Such email already associated with another account')
            return
          } else if (respResult.error === 'Locked') {
            this.errors.push('Your account already verified')
            return
          } else if (respResult.error === 'InvalidData') {
            respResult.metadata.forEach((errorData) => {
              if (errorData.param === 'email') {
                this.emailErrors.push(errorData.msg)
              }
            })
            return
          }
        }
        throw e
      }
      this.isProcessing = false
      this.$emit('done', response.data.result.operationId)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
