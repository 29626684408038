<template>
  <div>
    <div class="row">
      <div class="col-24">
        <h2 class="title-separator-left">Verification Code</h2>
        <p class="text-muted">Thanks! We sent a verification code to your email. Please check both your inbox and spam folder.</p>
      </div>
    </div>
    <form>
      <div class="row">
        <div class="col-24 pt-3 text-center" v-if="errors.length">
          <span class="text-danger" v-for="(error, index) in errors" :key="index">{{error}}</span>
        </div>
        <div class="col-24 pt-3">
          <div class="form-group">
            <label for="verificationCode" ref="verificationCodeLabel" v-animate:shake="errorLabelAnimate">Verification Code</label>
            <input type="text" name="verificationCode" id="verificationCode" class="form-control" v-focus v-model="verificationCode">
            <small class="form-text text-danger" v-for="(error, index) in verificationCodeErrors" :key="index">
              {{error}}
            </small>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12">
          <button type="button" class="btn btn-block btn-bc btn-gray-100" @click="$emit('cancel')">
            Back
          </button>
        </div>
        <div class="col-12">
          <ProcessingBtn type="submit" class="btn btn-block btn-bc btn-purple-900" :isProcessing="isProcessing" :onClick="submit">Verify</ProcessingBtn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash'
import config from '@/config'
import { getApiAuth } from '@/utils/credentials.utils'
import focus from '@/directives/focus'
import animate from '@/directives/animate'
import ProcessingBtn from '@/components/common/ProcessingBtn'

const { apiUrl } = config

export default {
  directives: {
    focus,
    animate
  },
  components: {
    ProcessingBtn
  },
  props: {
    operationId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isProcessing: false,
      verificationCode: '',
      errors: [],
      verificationCodeErrors: [],
      errorLabelAnimate: {
        clsList: ['text-danger']
      }
    }
  },
  methods: {
    resetErrors() {
      this.errors = []
      this.verificationCodeErrors = []
    },
    async submit(e) {
      e.preventDefault()
      this.resetErrors()
      if (this.validate()) {
        await this.verifyEmail()
      }
    },
    validate() {
      let isValid = true
      if (!this.verificationCode.length) {
        this.$refs.verificationCodeLabel.animate()
        isValid = false
      }
      return isValid
    },
    async verifyEmail() {
      this.isProcessing = true
      try {
        await this.$http.put(apiUrl + '/account/email/verification', {
          verificationCode: this.verificationCode,
          operationId: this.operationId,
        }, {
          auth: getApiAuth()
        })
      } catch (e) {
        this.isProcessing = false
        if (!_.isNil(e.response)) {
          const respResult = e.response.data.result
          if (respResult.error === 'Conflict') {
            this.errors.push('Such email already associated with another account')
            return
          } else if (respResult.error === 'Unauthorized') {
            this.verificationCodeErrors.push('Invalid verification code')
            return
          } else if (respResult.error === 'Locked') {
            this.errors.push('Your account already verified')
            return
          }  else if (respResult.error === 'InvalidData') {
            respResult.metadata.forEach((errorData) => {
              if (errorData.param === 'verificationCode') {
                this.verificationCodeErrors.push(errorData.msg)
              }
            })
            return
          }
        }
        throw e
      }
      this.isProcessing = false
      this.$emit('done')
    }
  }
}
</script>

<style scoped lang="scss">
</style>
